import React from "react";

import * as styles from "./Testimonial.module.css";
import quotationMark from "../../images/quotation-mark.png";

export default function Testimonial({ text, avatar, name, position }) {
  return (
    <div className={styles.testimonialContainer} data-sal="fade" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="700">
      <img className={styles.quotationMark} src={quotationMark} alt="" />
      <div className={styles.quote}>
        <p className={styles.text}>{text}</p>
        <div className={styles.writer}>
          <img className={styles.img} src={avatar} loading="lazy" alt={`${name}`} />
          <div className={styles.writerText}>
            <p className={styles.name}>{name}</p>
            <p className={styles.position}>{position}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
