import React from "react";
import Header from "../Header/Header";
import Solution from "../Solution/Solution";
import Mission from "../Mission/Mission";
import Testimonials from "../Testimonials/Testimonials";
import Join from "../Join/Join";
import * as styles from "./home.module.css";

export default function Home() {
  return (
    <div className={styles.container}>
      <Header />
      <Solution />
      <Mission />
      <Testimonials />
      <Join />
    </div>
  );
}
