import * as React from "react";
import "./Header.css";
import StartButton from "../Start-Button/Start-button";
import {useTranslation} from "react-i18next";
import * as styles from "./Header.module.css";
import mainComplete from "../../../static/main-complete.png";

export default function Header() {
    const {t, i18n} = useTranslation();

    return (
        <div className={styles.header_container}>
            <div className={styles.left_section}>
                <div className={styles.main_text}>
                    {t("header_section.title")}
                </div>
                <p className={styles.left_section_text}>
                    {t("header_section.description")}
                </p>
                <div className={styles.buttons_container}>
                    <StartButton text={t("get_started_free")}
                                 to={`${process.env.GATSBY_BASE_URL}/register?lang=${i18n.language}`}
                                 filled={true}
                                 chevron={false}/>
                    <StartButton text={t("talk_to_us")}
                                 to={t("demo_form")}
                                 filled={false}
                                 chevron={false}
                                 newTab={true}/>
                </div>
            </div>
            <div className={styles.right_section}>
                <img src={mainComplete} alt={"Recruitment software"}/>
            </div>
        </div>
    );
}
