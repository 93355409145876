import * as React from "react";
import * as styles from "./Feature.module.css";

export default function Feature({icon, title, description}) {
    return (
        <div className={styles.feature_container} data-sal="slide-right" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="400">
            {icon && <img className={styles.feature_icon} src={icon} alt={"Hiringmaster"}/>}
            <h1 className={styles.feature_title}>{title}</h1>
            <p className={styles.feature_description}>{description}</p>
        </div>
    );
}
