import * as React from "react";
import * as styles from "./Mission.module.css";
import cardIcon1 from "../../images/icons/card-icon-1.svg";
import cardIcon2 from "../../images/icons/card-icon-2.svg";
import cardIcon3 from "../../images/icons/card-icon-3.svg";
import cardIcon4 from "../../images/icons/card-icon-4.svg";
import cardIcon5 from "../../images/icons/card-icon-5.svg";
import cardBg1 from "../../images/card-bg-1.svg";
import cardBg2 from "../../images/card-bg-2.svg";
import cardBg3 from "../../images/card-bg-3.svg";
import cardBg4 from "../../images/card-bg-4.svg";
import cardBg5 from "../../images/card-bg-5.svg";
import Card from "../Card/Card";
import { useTranslation } from "react-i18next";

const CARD_COLORS = ['#f5af0a', '#a297f3', '#7b9eed', '#f490bc', '#eb9171']

export default function Mission() {
  const { t, i18n } = useTranslation();
  const missions = Array.from(t("mission_section.missions"));
  return <div className={styles.missionContainer}>
    <h6 className={styles.subTitle}>{t("mission_section.subtitle")}</h6>
    <h1 className={styles.mainTitle}>{t("mission_section.title")}</h1>
    <p className={styles.comment}>{t("mission_section.description")} </p>
    <div className={styles.cards}>
      <div className={styles.column1}>
        <Card icon={cardIcon2} iconColor={CARD_COLORS[0]} background={cardBg1} title={missions[0].title}
              description={missions[0].description} />
        <Card icon={cardIcon4} iconColor={CARD_COLORS[2]} background={cardBg3} title={missions[2].title}
              description={missions[2].description} />
        <Card icon={cardIcon1} iconColor={CARD_COLORS[4]} background={cardBg5} title={missions[4].title}
              description={missions[4].description} />
      </div>
      <div className={styles.column2}>
        <Card icon={cardIcon3} iconColor={CARD_COLORS[1]} background={cardBg2} title={missions[1].title}
              description={missions[1].description} />
        <Card icon={cardIcon5} iconColor={CARD_COLORS[3]} background={cardBg4} title={missions[3].title}
              description={missions[3].description} />
      </div>
    </div>
  </div>;
}
