import * as React from "react";
import * as styles from "./Join.module.css";
import StartButton from "../Start-Button/Start-button";
import {useTranslation} from "react-i18next";

export default function Join() {
    const { t, i18n } = useTranslation()
    return (
        <div className={styles.joinContainer}>
            <h1 className={styles.title}>{t("join_section.title")}</h1>
            <p className={styles.text}>{t("join_section.description")}</p>
            <div className={styles.startButtons}>
                <StartButton text={t("get_started_free")} chevron={true}
                             to={`${process.env.GATSBY_BASE_URL}/register?lang=${i18n.language}`}
                             filled={true}/>
                <StartButton text={t("talk_to_us")} chevron={false}
                             to={t("demo_form")}
                             newTab={true}
                             filled={false}/>
            </div>
        </div>
    );
}
