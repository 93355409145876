import React from "react";
import * as styles from "./Testimonials.module.css";
import Testimonial from "../Testimonial/Testimonial";
import { useTranslation } from "react-i18next";

export default function Testimonials() {
  const { t } = useTranslation();
  const testimonials = Array.from(t("testimonials_section.testimonials"));
  return (
    <div className={styles.testimonialsContainer}>
      {testimonials.map((testimonial, index) => (
        <Testimonial
          key={index}
          text={t(testimonial.message)}
          avatar={testimonial.photo} name={t(testimonial.author)} position={t(testimonial.position)} />))}
    </div>
  );
}
