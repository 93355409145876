import React from "react";
import * as styles from "./Card.module.css";

export default function Card({ icon, iconColor, background, title, description }) {
    return (
    <div className={styles.cardContainer} style={{
      backgroundImage: `url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }} data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease" data-sal-duration="400">
        <span className={styles.iconBackground} style={{background:iconColor}}>
                  <img className={styles.icon} src={icon}/>
        </span>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.description}>{description}</p>
    </div>
  );
}
