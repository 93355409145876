import * as React from "react";
import * as styles from "./solution.module.css";
import { useTranslation } from "react-i18next";
import Feature from "../Feature/Feature";

export default function Solution() {
  const { t } = useTranslation();
  return (
    <section className={styles.container}>
      <div className={styles.title}>
        <h6 className={styles.subTitle}>{t('features.sub_title')}</h6>
        <h1 className={styles.mainTitle}>{t('features.main_title')}</h1>
      </div>
      <div className={styles.features}>
        {
          Array.from(t("features.list")).map((feature, index) => (
            <Feature key={index} icon={feature.icon} title={feature.title} description={feature.description} />
          ))
        }
      </div>
    </section>
  );
}
