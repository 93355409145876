import * as React from "react";

import "../i18n";
import Home from "../components/Home/Home";
import Layout from "../components/Layout/Layout";
import Seo from "../components/shared/Seo";

export default function IndexPage() {
  return (
    <Layout>
      <Seo title={"Recruitment Software to simplify the recruitment process"} description={"Recruitment software built to simplify and give control of the hiring process. Start working smarter and faster today, with Hiringmaster"}/>
      <Home />
    </Layout>
  );
}
